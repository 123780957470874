import './styles/App.css'
import './styles/Mobile.css'
import { BrowserRouter } from "react-router-dom"
import Container from './components/Container'
import CssBaseline from '@mui/material/CssBaseline'
import ScrollToTop from './components/ScrollToTop'

function App() {
  return (
    <BrowserRouter>
      <CssBaseline />
      <ScrollToTop />
      <main>
        <Container />
      </main>
    </BrowserRouter>
  );
}

export default App;
