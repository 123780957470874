import Grid from "@mui/material/Grid"

const SectionFour = ({ mobile }) => {
    return (
        <>{mobile ? <MobileVersion /> : <DescVersion />}</>
    )
}

const MobileVersion = () => {
    return (
        <Grid container spacing={2} rowSpacing={{ xs: 4 }}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
                <h4>
                    A revolutionary platform that fully protects the legacy
                    and privacy of our Afterists and their beneficiaries.
                </h4>
                <p className="text">
                    <span className="text-peach text-bold">Decentralized</span><br />
                    After incorporates blockchain ledgers for notarization and IPFS for storage.
                </p>
                <p className="text">
                    <span className="text-peach text-bold">Autonomous</span><br />
                    Trustee members make a quorum that may execute the will, but After assists by expediting death confirmation.
                </p>
                <p className="text">
                    <span className="text-peach text-bold">Cryptographically Secure</span><br />
                    All documents are secured for trustee's eyes only using quantum resistant cryptography.
                </p>
                <p className="text">
                    <span className="text-peach text-bold">Self Proving</span><br />
                    After facilitates digital notarization and cryptographic hashing to make certain
                    documents are self-proving and incapable of fraud.
                </p>
            </Grid>
            <Grid item xs={1}></Grid>
        </Grid>
    )
}

const DescVersion = () => {
    return (
        <div className="sectionFourDesc">
            <Grid container spacing={2} rowSpacing={{ md: 4 }}>
                <Grid item md={7}></Grid>
                <Grid item md={4}>
                    <h4>
                        A revolutionary platform that fully protects the legacy
                        and privacy of our Afterists and their beneficiaries.
                    </h4>
                </Grid>
                <Grid item xs={1}></Grid>

                <Grid item md={1}></Grid>
                <Grid item md={10}>
                    <Grid item container>
                        <Grid item md={3}>
                            <p className="text s4t1">
                                <span className="text-peach text-bold">Decentralized</span><br />
                                After incorporates blockchain ledgers for notarization and IPFS for storage.
                            </p>
                        </Grid>
                        <Grid item md={3}>
                            <p className="text s4t2">
                                <span className="text-peach text-bold">Autonomous</span><br />
                                Trustee members make a quorum that may execute the will, but After assists by expediting death confirmation.
                            </p>
                        </Grid>
                        <Grid item md={3}>
                            <p className="text s4t3">
                                <span className="text-peach text-bold">Cryptographically Secure</span><br />
                                All documents are secured for trustee's eyes only using quantum resistant cryptography.
                            </p>
                        </Grid>
                        <Grid item md={3}>
                            <p className="text s4t4">
                                <span className="text-peach text-bold">Self Proving</span><br />
                                After facilitates digital notarization and cryptographic hashing to make certain
                                documents can be legally self-proving and incapable of fraud.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={1}></Grid>
            </Grid>
        </div>
    )
}

export default SectionFour
