import Grid from '@mui/material/Grid'
import { useState } from 'react'
import Footer from '../components/Footer'
import Login from './Login'

const SectionFive = () => {
    const [loginShow, setLoginShow] = useState(false)
    const [flow, setFlow] = useState(null)

    return (
        <div id="actionSection">
            {!loginShow ? <ActionButtons flow={setFlow} loginShow={setLoginShow} /> : <Login flow={flow}/>}

            <Footer />
        </div>
    )
}

const ActionButtons = ({ flow, loginShow }) => {
    const clickHeandler = (e) => {
        e.preventDefault()
        let target = e.target.getAttribute('target')
        if (target === 'pdf' || target === 'queue') {
            flow(prev => target)
            loginShow(prev => true)
        }
    }

    return (
        <>
            <div className="sectionFive">
                <Grid container spacing={2} rowSpacing={{ md: 4, xs: 4 }} >
                    <Grid item xs={1} md={3}></Grid>
                    <Grid item xs={10} md={6}>
                        <h2 className="s5h2">
                            Unlock your legacy with <span className="text-purple2">After</span>.
                        </h2>
                        <p className="text text-center">
                            After is your perfect solution for legacy and estate management with it’s comprehensive, trusted, immutable legacy planning, storage and execution.
                        </p>
                    </Grid>
                    <Grid item xs={1} md={3}></Grid>

                    <Grid item xs={1} md={3}></Grid>
                    <Grid item xs={10} md={3}>
                        <button className="button getstarted" onClick={clickHeandler} target="queue">
                            DISCOVER LATER
                        </button>
                    </Grid>
                    <Grid item xs={1} display={{ md: 'none' }}></Grid>

                    <Grid item xs={1} display={{ md: 'none' }}></Grid>
                    <Grid item xs={10} md={3}>
                        <button className="button learnnow" onClick={clickHeandler} target="pdf">
                            LEARN MORE
                        </button>
                    </Grid>
                    <Grid item xs={1} md={3}></Grid>
                </Grid>
            </div>
        </>
    )
}

export default SectionFive
