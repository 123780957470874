import useMediaQuery from '@mui/material/useMediaQuery'
import SectionOne from "../components/SectionOne"
import SectionTwo from "../components/SectionTwo"
import SectionThree from "../components/SectionThree"
import SectionFour from "../components/SectionFour"
import SectionFive from '../components/SectionFive'

const Main = () => {
    const mobile = useMediaQuery('(max-width:900px)')

    return (
        <div className="mainSection">
            <SectionOne />
            <SectionTwo />
            <SectionThree />
            <SectionFour mobile={mobile}/>
            <SectionFive />
        </div>
    )
}

export default Main