import Grid from '@mui/material/Grid'
import logo from '../logo.svg'
import il1 from '../assets/ILLUSTRATION_01.svg'

const SectionOne = () => {
    const handleClick = event => {
        event.preventDefault()
        document.getElementById('actionSection').scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    return (
        <Grid container spacing={2} rowSpacing={{ md: 4, xs: 4 }} alignItems="center">
            <Grid item md={1} xs={1}/>
            <Grid item md={11} xs={11}>
                <img src={logo} className="logo" alt="after" />
            </Grid>
            <Grid item md={2} xs={1}/>
            <Grid item md={3} xs={10}>                
                <h1>Protect and preserve your wealth.</h1>
                <p className="text">
                    After facilitates the creation of legal documents as they correlate to both crypto and digital assets. 
                    Because privacy is paramount, every Afterist's assets are cryptographically secure utitilizing
                    a lattice based cryptography.
                </p>
                <p className="text text-bold">Because we think about After.</p>
                <button className="button" onClick={handleClick}>
                    LET’S THINK ABOUT AFTER
                </button>
            </Grid>
            <Grid item md xs={12}>
                <img className="il_1" src={il1} alt="after" />
            </Grid>
        </Grid>
    )
}

export default SectionOne
