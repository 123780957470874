import Grid from '@mui/material/Grid'
import il4 from '../assets/ILLUSTRATION_04.svg'
import il3 from '../assets/ILLUSTRATION_03.png'

const SectionThree = () => {
    return (
        <div className="sectionThree">
            <Grid container spacing={2} rowSpacing={{ xs: 4, md: 4 }}>
                <Grid item md={3} xs={1} />
                <Grid item md={6} xs={10}>
                    <h2 className="s3h2">
                        After is unparalleled<br/>in estate document creation, storage and execution
                    </h2>
                </Grid>
                <Grid item md={3} xs={1} />


                <Grid item md={2} xs={1} />
                <Grid item md={8} display={{ xs: "none", md: "block" }}>
                    <div className="s3il">
                        <img src={il3} alt="after" />
                        <span className="s3span1">Encompasses digital and physical assets</span>
                        <span className="s3span2">Uniquely customized for each individual</span>
                        <span className="s3span3">Automates and streamlines traditional processes</span>
                    </div>
                </Grid>
                <Grid item xs={10} display={{ md: "none" }}>
                    <div className="s3il"></div>
                    <p className="text text-center"><span className="text-bold">Easily plan</span> and take control of your legacy</p>
                    <p className="text text-center">Change as estate <span className="text-bold">continues to grow</span></p>
                    <p className="text text-center">Assistance in <span className="text-bold">crypto legacy management</span></p>
                </Grid>
                <Grid item md xs={1} />


                <Grid item md={2} xs={1} />
                <Grid item md={4} xs={10}>
                    <h2 className="s2h2_2">
                        <span className="text-peach">After</span> defines
                        the future for generations
                        to come.
                    </h2>
                </Grid>
                <Grid item xs={1} display={{ md: "none" }} />


                <Grid item xs={1} display={{ md: "none" }} />
                <Grid item md={4} xs={10}>
                    <p className="text">
                        After is the only company built on the blockchain utilizing smart contracts to create an immutable, private distribution of assets
                        at an event-based trigger in the future across all networks and mediums in a beautiful, simple, easy-to-use app.
                    </p>
                </Grid>
                <Grid item md={2} xs />

                <Grid item xs={12} display={{ md: 'none' }}>
                    <img src={il4} alt="after" className="s4il" />
                </Grid>

            </Grid>
        </div>
    )
}

export default SectionThree
