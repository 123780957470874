import '../styles/Tokenomics.css'
import Grid from '@mui/material/Grid'
import { Link } from "react-router-dom"
import Footer from '../components/Footer'
import logo from '../logo.svg'
import obolLogo from '../assets/obol-logo.svg'
import obolLogoArrows from '../assets/obol-witharrow.svg'
import { createTheme, ThemeProvider } from '@mui/system'

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 480,
            md: 1024,
            lg: 1280,
            xl: 1536,
        },
    }
})

const Tokenomics = () => {
    return (
        <ThemeProvider theme={theme}>
            <div className="mainSection tokenomics">
                <div className='tokenomicsWrap'>
                    <Grid container spacing={0} alignItems="top">
                        <Grid item xs={1} />
                        <Grid item xs={11}>
                            <Link to="/">
                                <img src={logo} className="logo" alt="after" />
                            </Link>
                        </Grid>

                        <Grid item xs={1} />
                        <Grid item xs={10}>
                            <Intro />
                            <OBOL />
                            <Buy />
                        </Grid>
                        <Grid item xs />
                    </Grid>
                </div>
                <Footer />
            </div>
        </ThemeProvider>
    )
}

const Intro = () => {
    return (
        <Grid container item alignItems="flex-start" justifyContent="space-around" className="tokenomics-content">
            <Grid item sm={1} lg={2} xl={3} />
            <Grid item sm={10} xs={12} lg={8} xl={6}>
                <h1 className="text-center">Introducing<br /><span className="text-peach">#OBOL</span> token</h1>
                <div className="slogan text-center">Vulputate magnis a ad ante vestibulum in a tincidunt at ligula in augue venenatis a phasellus conubia taciti sodales mus pharetra dui.</div>
            </Grid>
            <Grid item sm={1} lg={2} xl={3} />

            <Grid item md={4} xs={12}>
                <h2>WHAT IS #OBOL</h2>
                <span className="text-bold">
                    Etiam fermentum eros euismod mi malesuada magna
                    <span className="text-peach text-bold">#OBOL</span> a condim entum senectus vestibulum cum.
                </span>
                <button className="btnDownloadWhitePaper">DOWNLOAD WHITE PAPER</button>
            </Grid>
            <Grid item md={4} xs={12}>
                <p>
                    Sodales vestibulum neque aptent nam posuere eget dis et etiam parturient class curae non gravida convallis.
                    Nulla fusce adipiscing egestas metus ut mollis adipiscing parturient cum sodales nisi sit a a suscipit vestibulum enim sagittis non fames.
                </p>
                <p>
                    A neque euismod et eu mus vestibulum mattis at montes parturient sociosqu suscipit facilisi at ac.
                </p>
                <p>
                    Vestibulum at orci per integer condimentum cum augue vitae vestibulum est dictum consectetur suspendisse a massa a in ut
                </p>
                <p>
                    Suspendisse adipiscing a dignissim posuere velit amet nisi adipiscing cum.
                </p>
            </Grid>
        </Grid>
    )
}

const OBOL = () => {
    return (
        <Grid container item alignItems="flex-start" justifyContent="center" className="tokenomics-content">
            <Grid item lg={6} xs={12} sm={9}>
                <div className="text-center token-section">
                    <h2 className="mb15">#OBOL TOKENOMICS</h2>
                    <span className="text-bold">
                        Etiam fermentum eros euismod mi malesuada magna <span className="text-peach text-bold">#OBOL</span> a condim entum senectus vestibulum cum.
                    </span>
                </div>
            </Grid>

            <Grid container item xs={12} alignItems="flex-start" justifyContent="space-between" className="obolLogoSection">

                <Grid container item xs={12} alignSelf="center" display={{ xs: "block", md: "none" }}>
                    <div className="text-center obolLogoWrap">
                        <img src={obolLogo} alt="#OBOL" className="obolMobLogo" />
                    </div>
                </Grid>

                <Grid container item sm={5} md={2} xl={3} xs={12}>
                    <Grid item xs={12}>
                        <div className="text-right obol-subsec">
                            <h3 className="text-peach">Reserves</h3>
                            <p>Scelerisque primis sem cum porttitor dictum commodo a sed natoque purus turpis a hac cras hac.</p>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="text-right obol-subsec">
                            <h3 className="text-peach">Team</h3>
                            <p>Scelerisque primis sem cum porttitor dictum commodo a sed natoque purus turpis a hac cras hac.</p>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="text-right">
                            <h3 className="text-peach">Community Mining</h3>
                            <p>Scelerisque primis sem cum porttitor dictum commodo a sed natoque purus turpis a hac cras hac.</p>
                        </div>
                    </Grid>
                </Grid>

                <Grid item md={8} xl={6} alignSelf="center" display={{ xs: "none", md: "block" }}>
                    <div className="text-center obolLogoWrap">
                        <img src={obolLogoArrows} alt="#OBOL" className="obolBigLogo" />
                    </div>
                </Grid>

                <Grid item sm={2} display={{ xs: "none", md: "none", sm: "block" }} />

                <Grid container item sm={5} md={2} xl={3} xs={12}>
                    <Grid item xs={12}>
                        <div className="text-left obol-subsec">
                            <h3 className="text-peach">Liquidity Fund</h3>
                            <p>Scelerisque primis sem cum porttitor dictum commodo a sed natoque purus turpis a hac cras hac.</p>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="text-left obol-subsec">
                            <h3 className="text-peach">Treasury Fund</h3>
                            <p>Scelerisque primis sem cum porttitor dictum commodo a sed natoque purus turpis a hac cras hac.</p>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="text-left">
                            <h3 className="text-peach">Token Sale</h3>
                            <p>Scelerisque primis sem cum porttitor dictum commodo a sed natoque purus turpis a hac cras hac.</p>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const Buy = () => {
    return (
        <Grid container item alignItems="flex-start" justifyContent="space-around" className="tokenomics-content buy-obol">
            <Grid item md={4} xs={12}>
                <h2>HOW TO BUY #OBOL</h2>
                <span className="text-bold">
                    Sit sit montes consequat sed lacinia a mauris ante volutpat nisi vestibulum vestibulum penatibus <span className="text-peach text-bold">#OBOL</span> donec.
                </span>
                <button>BUY #OBOL TOKEN</button>
            </Grid>
            <Grid item md={4} xs={12}>
                <p>
                    Sodales vestibulum neque aptent nam posuere eget dis et etiam parturient class curae non gravida convallis.
                    Nulla fusce adipiscing egestas metus ut mollis adipiscing parturient cum sodales nisi sit a a suscipit vestibulum enim sagittis non fames.
                </p>
                <p>
                    Vestibulum at orci per integer condimentum cum augue vitae vestibulum est dictum consectetur suspendisse a massa a in ut
                </p>
                <p>
                    Suspendisse adipiscing a dignissim posuere velit amet nisi adipiscing cum.
                </p>
            </Grid>
        </Grid>
    )
}

export default Tokenomics
