import { useCallback, useEffect, useRef, useState } from "react"
import Grid from '@mui/material/Grid'
import KeyHole from '../assets/keyhole-icon.svg'
import LockIco from '../assets/icon-lock.svg'
import PDFIco from '../assets/icon-pdf.svg'
import DropIco from '../assets/door-icon.svg'
import Web3 from "web3"
import getWalletConnect from '../utils/getWalletConnect'

const defualtLoginState = {
    account: '',
    networkError: false,
    view: 'login',
    fetchDone: true
}

const Login = ({ flow }) => {

    const [loginState, setLoginState] = useState(defualtLoginState)
    const accountState = useRef()

    accountState.current = loginState.account

    const accountsChanged = useCallback((accounts) => {
        if (accounts.length) {
            console.log('Account Changed', accounts[0])
            setLoginState(prev => ({ ...prev, account: accounts[0].toLowerCase() }))
        }
        else {
            console.log('Account disconnected')
            disconnect()
        }
    }, [])

    const chainChanged = useCallback((input) => {
        const nid = typeof input == 'string' ? Web3.utils.hexToNumber(input) : input
        if (nid !== 1) {
            console.log('Invalid network', nid)
            setLoginState(prev => ({ ...prev, networkError: true, view: 'login' }))
            return false
        }
        else {
            console.log('Network Id', nid)
            setLoginState(prev => ({ ...prev, networkError: false }))
            return true
        }
    }, [])

    const disconnect = () => {
        setLoginState(prev => defualtLoginState)
    }

    const checkAirDrop = async () => {
        if (!loginState.fetchDone) return
        console.log('Fetch', loginState.account)

        try {
            let res = await fetch(`https://after.xyz/api/airdrop/${loginState.account}`)
            res = await res.json()
            if (res.success) {
                res.status = 1
                if (res.status && res.status === 1) {
                    //Send Post request
                    window.setTimeout(() => {airDrop()}, 3000)
                    setLoginState(prev => ({ ...prev, view: 'confirm' }))
                }

                if (res.status && res.status === 3) {
                    //Message: Your address was already accepted
                    setLoginState(prev => ({ ...prev, view: flow }))
                }
            }
            else {
                setLoginState(prev => ({ ...prev, view: res.message }))
            }
        }
        catch (e) {
            console.log('fetch error')
            setLoginState(prev => ({ ...defualtLoginState, view: 'Sorry! Error occurred. Please try again.' }))
        }
        finally {
            setLoginState(prev => ({ ...prev, fetchDone: true }))
        }
    }

    const airDrop = async () => {        
        try {
            let res = await fetch(`https://after.xyz/api/airdrop/${loginState.account}`, { method: 'POST' })
            res = await res.json()
            if (res.success && (res.status === 2 || res.status === 3)) setLoginState(prev => ({ ...prev, view: flow }))
        }
        catch (e) {
            console.log('fetch airdrop error')
            setLoginState(prev => ({ ...defualtLoginState, view: 'Sorry! Error occurred. Please try again.' }))
        }
    }

    const connect = async () => {
        const web3 = window.cfweb3 || await getWalletConnect(accountsChanged, chainChanged, disconnect)
        const nid = await web3.eth.net.getId()
        //Check Network ID
        if (!chainChanged(nid)) return false

        //Get Account
        if (!loginState.account || !loginState.account.length) {
            if (typeof web3.currentProvider.infuraId === 'undefined' && !window.awaitingAccounts) {
                try {
                    await web3.currentProvider.request({ method: 'eth_requestAccounts' })
                    accountsChanged(await web3.eth.getAccounts())
                }
                catch (err) {
                    console.log('Get Account Error', err)
                    disconnect()
                }
            } else {
                accountsChanged(await web3.eth.getAccounts())
            }
        }
        else {
            accountsChanged(await web3.eth.getAccounts())
        }
    }

    useEffect(() => {
        if (loginState.account.length) {
            checkAirDrop()
        }
    }, [loginState.account])

    useEffect(() => {
        document.getElementsByClassName('sectionFive')[0].scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, [loginState.view]) 

    console.log(loginState)

    switch (loginState.view) {
        case 'login':
            return (<><LoginForm loginState={loginState} connect={connect} /></>)

        case 'pdf':
            return (<><PDF /></>)

        case 'queue':
            return (<><Queue /></>)

        case 'confirm':
            return (<><ConfirmWallet /></>)

        default:
            return (<><Message message={loginState.view} /></>)
    }

}

const LoginForm = ({ loginState, connect }) => {
    return (
        <div className="sectionFive loginMessage" id="loginForm">
            <Grid container spacing={2} rowSpacing={{ md: 4, xs: 4 }} >
                <Grid item xs={1} md={4}></Grid>
                <Grid item xs={10} md={4}>
                    <img src={KeyHole} alt="after" className="keyHole"/>
                    <h4 className="text-center">
                        Please login and verify your wallet ownership.
                    </h4>
                    <div className="text-login text-center">
                        This application uses read-only access to your wallet. Please sign to acknowledge.
                    </div>
                    {loginState.networkError ? <div className="text-center text-bold text-peach net-err">Please connect your wallet to the Ethereum Mainnet</div> : <button onClick={connect}>AUTHORIZE ACCESS</button>}
                </Grid>
                <Grid item xs={1} md={4}></Grid>
            </Grid>
        </div>
    )
}

const ConfirmWallet = () => {
    return (
        <div className="sectionFive loginMessage" id="confirmMessage">
            <Grid container spacing={2} rowSpacing={{ md: 4, xs: 4 }} >
                <Grid item xs={1} md={4}></Grid>
                <Grid item xs={10} md={4}>
                    <img src={DropIco} alt="after" className="dropIcon"/>
                    <h4 className="text-center">
                        Your wallet is connected.
                    </h4>
                    <div className="text text-center">
                        You will be shortly redirected to a pretium nisl parturient
                        eleifend id quis consequat penatibus condimentum.
                    </div>
                </Grid>
                <Grid item xs={1} md={4}></Grid>
            </Grid>
        </div>
    )
}

const Message = ({ message }) => {
    return (
        <div className="sectionFive loginMessage" id="otherMessage">
            <Grid container spacing={2} rowSpacing={{ md: 4, xs: 4 }} >
                <Grid item xs={1} md={4}></Grid>
                <Grid item xs={10} md={4}>
                    <h4 className="text-center">
                        Something wrong.
                    </h4>
                    <div className="text text-center">
                        {message}
                    </div>
                </Grid>
                <Grid item xs={1} md={4}></Grid>
            </Grid>
        </div>
    )
}

const Queue = () => {
    return (
        <div className="sectionFive loginMessage" id="queueMessage">
            <Grid container spacing={2} rowSpacing={{ md: 4, xs: 4 }} >
                <Grid item xs={1} md={4}></Grid>
                <Grid item xs={10} md={4}>
                    <img src={LockIco} alt="after" />
                    <h4 className="text-center">
                        Thank you for ullamcorper <span className="text-purple2">After</span> consequat vehicula sodales senectus!
                    </h4>
                    <div className="text text-center">
                        Luctus cum parturient velit enim taciti ullamcorper a penatibus himenaeos habitant maecenas eget orci hac eros tempor.
                    </div>
                </Grid>
                <Grid item xs={1} md={4}></Grid>
            </Grid>
        </div>
    )
}

const PDF = () => {

    const openPDF = (e) => {
        e.preventDefault()
        window.open('/About-After.pdf')
    }

    return (
        <div className="sectionFive loginMessage" id="pdfMessage">
            <Grid container spacing={2} rowSpacing={{ md: 4, xs: 4 }} >
                <Grid item xs={1} md={4}></Grid>
                <Grid item xs={10} md={4}>
                    <img src={PDFIco} alt="after" />
                    <h4 className="text-center">
                        Thank you for ullamcorper <span className="text-purple2">After</span> consequat vehicula sodales senectus!
                    </h4>
                    <div className="text text-center">
                        Dolor scelerisque ut venenatis eleifend sed adipiscing presentation (PDF file 1.6MB)  torquent a penatibus erat donec.
                    </div>
                    <button className="button" onClick={openPDF}>DOWNLOAD</button>
                </Grid>
                <Grid item xs={1} md={4}></Grid>
            </Grid>
        </div>
    )
}

export default Login