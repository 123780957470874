import {Routes, Route} from "react-router-dom"
import Main from '../pages/Main'
import Terms from '../pages/Terms'
import Tokenomics from '../pages/Tokenomics'

const Container = () => {
    return(                
        <Routes>            
            <Route exact path="/" element={<Main/>} />
            <Route path="/terms" element={<Terms/>} />
            <Route path="/tokenomics" element={<Tokenomics/>} />
        </Routes>        
    )
}

export default Container