import Web3 from 'web3';
import WalletConnectProvider from '@walletconnect/web3-provider';

const ETH_MAINNET_PROJECT_ID = 'ef17c36f93214a32be6ab7c06b68d93c';

const getWalletConnect = (accountsChanged, chainChanged, disconnect) =>
    new Promise((resolve, reject) => {
        async function callbacks (web3, accountsChanged, chainChanged) {
            if (typeof chainChanged == 'function') {
                web3.currentProvider.on('chainChanged', (chainId) => {
                    window.cfweb3chainid = chainId;
                    window.sessionStorage.setItem('cfweb3chainid', chainId || null);
                    chainChanged(chainId);
                });
            } else {
                web3.currentProvider.on('chainChanged', (chainId) => {
                    window.cfweb3chainid = chainId;
                    window.sessionStorage.setItem('cfweb3chainid', chainId || null);
                });
            }
            if (typeof accountsChanged == 'function') {
                web3.currentProvider.on('accountsChanged', (accounts) => {
                    accountsChanged(accounts);
                    window.cfweb3accounts = accounts;
                    window.sessionStorage.setItem('cfweb3accounts', JSON.stringify(accounts || []));
                });
            }
            else {
                web3.currentProvider.on('accountsChanged', (accounts) => {
                    window.cfweb3accounts = accounts;
                    window.sessionStorage.setItem('cfweb3accounts', JSON.stringify(accounts || []));
                });
            }
            if (typeof disconnect == 'function') {
                web3.currentProvider.on('disconnect', (code, reason) => {
                    window.cfweb3accounts = [];
                    window.sessionStorage.setItem('cfweb3accounts', null);
                    disconnect(code, reason);
                });
            } else {
                web3.currentProvider.on('disconnect', (code, reason) => {
                    window.cfweb3accounts = [];
                    window.sessionStorage.setItem('cfweb3accounts', null);
                });
            }
            return web3;
        }

        async function loaded () {
            try {
                // Modern dapp browsers and extensions connecting via local RPC
                if (window.ethereum) {
                    console.log('Injected ethereum detected.');
                    const web3 = new Web3(window.ethereum);
                    resolve(callbacks(web3, accountsChanged, chainChanged, disconnect));
                }
                // Legacy dapp browsers/extensions using window.web3
                else if (typeof window.web3 !== 'undefined') {
                    console.log('Injected web3 detected.');
                    const web3 = window.web3;
                    resolve(callbacks(web3, accountsChanged, chainChanged, disconnect));
                }
                // Use localhost; use dev console port by default
                else if (window.location.href.indexOf('localhost') === 7) {
                    console.log('Using Local RPC.');
                    const web3 = new Web3(new Web3.providers.HttpProvider(
                        'http://127.0.0.1:8545'
                    ));
                    resolve(callbacks(web3, accountsChanged, chainChanged, disconnect));
                }
                // Possibly on mobile, use Wallet Connect as a last resort
                else {
                    console.log('Using Infura & WalletConnect.');
                    const web3 = new Web3(new WalletConnectProvider({
                        infuraId: ETH_MAINNET_PROJECT_ID,
                    }));
                    web3.currentProvider.once('accountsChanged', (accounts) => {
                        resolve(callbacks(web3, accountsChanged, chainChanged, disconnect));
                    });

                    // Trigger modal
                    await web3.currentProvider.enable();
                }
            } catch (e) {
                if (typeof disconnect == 'function') {
                    disconnect(0, e.toString());
                }
            }
        }

        if (document.readyState === 'complete') {
            setTimeout(loaded, 50);
        }
        else {
            window.addEventListener('load', loaded, {once: true});
        }
    });

export default getWalletConnect;
