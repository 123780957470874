import Grid from '@mui/material/Grid'
import il2 from '../assets/ILLUSTRATION_02.svg'

const SectionTwo = () => {
    return (
        <Grid container spacing={2} rowSpacing={{ md: 4, xs: 4 }} alignItems="center">
            <Grid item md display={{ xs: "none", md: "block"}}>
                <img className="il_2" src={il2} alt="" />
            </Grid>
            <Grid item xs={1} display={{md: "none"}}/>
            <Grid item md={3} xs={10}>
                <h3 className="s2h3">
                    Generate a legal will and trust with the blockchain
                </h3>
                <p className="text">
                    <span className="text-purple">After</span> assists each holder in generating immutable, impenetrable legal documents with on-chain permanence.
                    Afterist’s have the capability to amend their estate plan to modify or add assets at any time.
                    Holders may include sensitive credentials in the Vault, which can be either digital or traditionally documented.
                </p>
                <p className="text">
                    This allows the trustee to “connect” and manage assets, even those that are traditionally non-transferable.
                </p>
            </Grid>

            <Grid item md={2} display={{xs: "none", md: "block"}}/>

            <Grid item xs={12} display={{ md: "none" }}>
                <img className="il_2" src={il2} alt="" />
            </Grid>
        </Grid>
    )
}

export default SectionTwo
