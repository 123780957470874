import Grid from '@mui/material/Grid'
import { Link } from "react-router-dom"
import Footer from '../components/Footer'
import logo from '../logo.svg'

const Terms = () => {
    return (
        <div className="mainSection terms">
            <Grid container spacing={2} rowSpacing={{ md: 4, xs: 4 }} alignItems="center">
                <Grid item md={1} xs={1} />
                <Grid item md={11} xs={11}>
                    <Link to="/">
                        <img src={logo} className="logo" alt="after" />
                    </Link>
                </Grid>

                <Grid item md={3} xs={1} />
                <Grid item md={6} xs={10}>
                    <h1>Terms and Conditions</h1>
                    <p className="text">
                        Mi hac a fames cursus id a nunc erat mus potenti arcu vestibulum parturient parturient facilisis nostra consequat arcu dis eu risus parturient odio vestibulum aliquam. Dignissim cum libero a euismod lacinia arcu nisl massa adipiscing nunc porttitor eu porttitor parturient fermentum a himenaeos fames.
                    </p>
                    <p className="text">Ante facilisi euismod fermentum montes fermentum quisque et ullamcorper potenti nascetur scelerisque consectetur eu odio parturient volutpat cras scelerisque interdum vitae ipsum id sociosqu volutpat ridiculus. Vel faucibus ultrices mi aliquam morbi vehicula adipiscing adipiscing convallis vestibulum vestibulum tempus aliquet litora erat curae sagittis egestas elementum a mus quis fames parturient.</p>
                    <h2 className="small">Small paragraph title</h2>
                    <p className="text">
                        Mi hac a fames cursus id a nunc erat mus potenti arcu vestibulum parturient parturient facilisis nostra consequat arcu dis eu risus parturient odio vestibulum aliquam. Dignissim cum libero a euismod lacinia arcu nisl massa adipiscing nunc porttitor eu porttitor parturient fermentum a himenaeos fames.
                    </p>
                    <p className="text">Ante facilisi euismod fermentum montes fermentum quisque et ullamcorper potenti nascetur scelerisque consectetur eu odio parturient volutpat cras scelerisque interdum vitae ipsum id sociosqu volutpat ridiculus. Vel faucibus ultrices mi aliquam morbi vehicula adipiscing adipiscing convallis vestibulum vestibulum tempus aliquet litora erat curae sagittis egestas elementum a mus quis fames parturient.</p>
                    <h2 className="small">Small paragraph title</h2>
                    <p className="text">
                        Mi hac a fames cursus id a nunc erat mus potenti arcu vestibulum parturient parturient facilisis nostra consequat arcu dis eu risus parturient odio vestibulum aliquam. Dignissim cum libero a euismod lacinia arcu nisl massa adipiscing nunc porttitor eu porttitor parturient fermentum a himenaeos fames.
                    </p>
                    <p className="text">Ante facilisi euismod fermentum montes fermentum quisque et ullamcorper potenti nascetur scelerisque consectetur eu odio parturient volutpat cras scelerisque interdum vitae ipsum id sociosqu volutpat ridiculus. Vel faucibus ultrices mi aliquam morbi vehicula adipiscing adipiscing convallis vestibulum vestibulum tempus aliquet litora erat curae sagittis egestas elementum a mus quis fames parturient.</p>
                    <p className="text text-bold">Sample of unordered list:</p>
                    <ul>
                        <li>Consequat sem euismod facilisis vestibulum adipiscing sodales sociosqu eu per scelerisque lobortis congue libero ut. Diam vulputate in mauris pulvinar id parturient hac id  ullamcorper adipiscing nisi mauris adipiscing maecenas ornare elementum suspendisse ad mus tincidunt sociosqu tincidunt 	cursus id condimentum egestas ad aliquet. Vestibulum est adipiscing consectetur ad curabitur.</li>
                        <li>Scelerisque consectetur dictumst ante id tempus a ullamcorper vitae in. A posuere nullam ullamcorper condimentum a volutpat ut hac vestibulum tincidunt ac in a maecenas vivamus nisi convallis.</li>
                        <li>Class vestibulum massa vestibulum a curae ligula duis a per varius et parturient bibendum suspendisse 	ridiculus dis torquent eros sapien. Suspendisse condimentum risus facilisis bibendum a per diam 	integer a consequat convallis fusce neque adipiscing mattis faucibus arcu erat massa scelerisque
                            Aporta malesuada est a. Sociis fusce nascetur id leo ullamcorper parturient arcu vestibulum eget venenatis dictumst a maecenas a quis cubilia ad torquent vestibulum.
                        </li>
                    </ul>
                </Grid>
                <Grid item md xs />
            </Grid>
            <Footer />
        </div>
    )
}

export default Terms
