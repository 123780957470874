import Grid from '@mui/material/Grid'
import Link from '../utils/Link'
import footerIl from '../assets/footer.svg'
import footerIlMob from '../assets/footer_mob.svg'

const Footer = () => {
    return (
        <Grid item xs={12}>
            <footer>
                <p className="text text-center quote">
                    What you leave behind is not what is engraved in stone monuments,<br/>but what is woven into the lives of others. ~ Pericles
                </p>
                <img src={footerIl} alt="after" className="footerIl" />
                <img src={footerIlMob} alt="after" className="footerIlMob" />
                <div className="footer-terms">
                    <Grid container item spacing={2} rowSpacing={{ md: 4, xs: 4 }}>
                        <Grid item xs={1} lg={1} />
                        <Grid item xs={11} lg={2}>
                            <span className="footer-text">© After {(new Date().getFullYear())}. All rights reserved.</span>
                        </Grid>
                        <Grid item xs={1} display={{ lg: "none" }} />
                        {/* <Grid item xs={6} md={2}>
                            <Link to="/terms">
                                <span className="footer-text text-bold text-peach">Terms and Conditions</span>
                            </Link>
                        </Grid> */}
                        <Grid item xs lg >
                            <Link to="/terms">
                                <span className="footer-text text-bold text-peach">Terms and Conditions</span>
                            </Link>
                            <Link to="https://docs.after.fund/fundamentals/tokenomics">
                                <span className="footer-text text-bold text-peach">Tokenomics</span>
                            </Link>
                            <Link to="https://docs.after.fund">
                                <span className="footer-text text-bold text-peach">Litepaper</span>
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            </footer>
        </Grid>
    )
}

export default Footer